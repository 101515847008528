(function () {
    var $bizCenter = $("#bizCenterLocal");
    var didScroll = false;
    var lastScrollTop = 0;
    var headerHeight = 80;

    var $header = $('header');

    var MOBILEWIDTH = 768;

    $(document).ready(function(){
        //is device check
        if(document.documentElement.clientWidth > MOBILEWIDTH) {
            $bizCenter.removeClass('mobile');
        } else {
            $bizCenter.removeClass('pc').addClass('mobile');
        }

        $('.dimmed.loading').removeClass('loading');
    });

    // $(window).on('load', function () {
        // var _window = $(this);
        //
        // if(_window.scrollTop() == 0) {
        //     $header.removeClass('');
        //     // banner 때문에 추가
        //     // $bizCenter.removeClass('on');
        // } else {
        //     $header.removeClass().addClass('up');
        //     // banner 때문에 추가
        //     // $bizCenter.addClass('on');
        // }
    // });

    $(window).on('scroll', function () {
        didScroll = true;
        if(!$('header').find('nav').hasClass("mobile-on")) {
            $('header').find('nav').removeClass('mobile-on');
        }
    });

    setInterval(function() {
        if(didScroll) {
            hasScrolled();
            // hideStickyFooter();
        }
    }, 200);

    // 배경 interval 주석
    // setInterval(function() {
    //     if ($('main').hasClass('even')) {
    //         $('main').removeClass('even');
    //         $('main').addClass('odd');
    //     } else {
    //         $('main').removeClass('odd');
    //         $('main').addClass('even');
    //     }
    // }, 5000);

    function hasScrolled() {
        var windowScrollTop = $(window).scrollTop();
        var $stickyFooter = $('.sticky-footer');

        if(windowScrollTop == 0 ) {
            // $header.removeClass();
            // $stickyFooter.removeClass('on');

            // mobile menu Flag
            if ($(".mobile nav").hasClass('mobile-on')) {
                $header.addClass('on');
                didScroll = false;
            }
        }

        if(Math.abs(lastScrollTop - windowScrollTop) <= headerHeight) {
            return;
        }

        if(windowScrollTop > lastScrollTop && windowScrollTop > headerHeight) {
            //console.log("스크롤 업");
            if (!$(".mobile nav").hasClass('mobile-on')) {
                $header.removeClass('on').addClass('up');
            }
            // if(windowScrollTop == 0) {
            //     $stickyFooter.removeClass().addClass('sticky-footer');
            // }
        } else {
            // if(windowScrollTop == 0) {
            //     // $header.removeClass('');
            //     $stickyFooter.removeClass().addClass('sticky-footer');
            // } else {
            //     $header.removeClass('up').addClass('on');
            // }
            //console.log("스크롤 다운");
        }
        // $stickyFooter.addClass('on');
        $bizCenter.addClass('on');
        lastScrollTop = windowScrollTop;
    }
    function hideStickyFooter(  ) {
        var wstp = $(window).scrollTop();
        if ($("footer.common").offset() && $("footer.common").offset().top) {
            var _footer = $("footer.common").offset().top;
        } else {
            var _footer = 0;
        }
        var screenHeight = $(window).height();
        var $stickyFooter = $(".sticky-footer");

        if (_footer > 0 && (wstp) >= ( _footer - screenHeight) ) {
            $stickyFooter.removeClass('on');
        }
    }

    $(window).on('resize', function () {
        if( $(this).width() > MOBILEWIDTH) {
            $bizCenter.removeClass("mobile").addClass("pc");
        } else {
            $bizCenter.removeClass('pc').addClass('mobile');
        }
    });

    //-------------------------------------------------
    // Common Weble, Platform, Product(sub weble,fefelo,ad)
    //-------------------------------------------------
    $(".tab-wrap h2").on('click', function() {
        var $tablis = $(".tab-wrap h2"),
            $tabs = $(".tab");
        var $idx = $(this).index();

        $tablis.removeClass('on');
        $tabs.removeClass('on');

        if( $(this).has("a").length >= 1) {
            location.href = $(this).find("a").attr("href");
        }  else {
            $(this).addClass('on');
            $tabs.eq($idx).addClass('on');
        }
    });

})();

(function() {
    var timer=[];
    timer[0] = setTimeout(function() {
        $(".pc .sub .top-section .step1").addClass('animated fadeInUp');
    }, 700);
    timer[1] = setTimeout(function() {
        $(".pc .sub .top-section .step2").addClass('animated fadeInUp');
        for(var i=0;i < timer.length; i++) {
            clearTimeout(timer[i]);
        }
    }, 1000);
})();

(function() {
    $(".m-menu").on('click', function(e) {
        e.preventDefault();
        var $header = $('header');
        $header.find('nav').toggleClass('mobile-on');

        if($header.find('nav').hasClass('mobile-on')) {
            $header.addClass('on');
        }
    });
    $(".m-close").on('click', function(e) {
        e.preventDefault();
        var $header = $('header');
        $header.find('nav').toggleClass('mobile-on');
        $header.removeClass('on');

    });
})();

function addComma(value){
    value = value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return value;
}
